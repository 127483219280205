import { useState } from 'react';
import { observer } from 'mobx-react';
import { useHenshuApp } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';
import { List, Accordion, Title, Header, Body, Button } from '@strategies/ui';

import Section from '../Section';


export default observer(function Process() {
    const { bindTo } = useHenshu();
    const { upload } = useHenshuApp().persist;
    const [phase, setPhase] = useState(0);

    return <>
        <Section index={2} name="Process">
            <div className='body'>
                <henshu.richtext {...bindTo('process.body')} />
            </div>
        </Section>

        

        <Section index={2} name="Accordions">
            <div id="morris" />

            <div className="buttons">
                <henshu.h3 {...bindTo('morris.htitle')} />
                <henshu.h2 {...bindTo('morris.hsubtitle')} />
                {/* <Button onClick={() => {}}>
                    Rochester
                </Button> */}
            </div>

            <henshu.img
                get={() => bindTo('morris.timeline').get()}
                set={async (t) => bindTo('morris.timeline').set(t ? await upload(t) : t)}
            />

            <henshu.richtext className="belowgraphic" {...bindTo('Process.belowgraphic.morris')} />

        </Section>


        <Section index={2} name="Accordions">
            <div id="crookston" />

            <div className="buttons">
                <henshu.h3 {...bindTo('crookston.htitle')} />
                <henshu.h2 {...bindTo('crookston.hsubtitle')} />
                {/* <Button onClick={() => {}}>
                    Rochester
                </Button> */}
            </div>

            <henshu.img
                get={() => bindTo('crookston.timeline').get()}
                set={async (t) => bindTo('crookston.timeline').set(t ? await upload(t) : t)}
            />

            <henshu.richtext className="belowgraphic" {...bindTo('Process.belowgraphic.crookston')} />

        </Section>

        <Section index={2} name="Accordions">
            <div id="duluth" />

            <div className="buttons">
                <henshu.h3 {...bindTo('duluth.htitle')} />
                <henshu.h2 {...bindTo('duluth.hsubtitle')} />
                {/* <Button onClick={() => {}}>
                    Rochester
                </Button> */}
            </div>

            <henshu.img
                get={() => bindTo('duluth.timeline').get()}
                set={async (t) => bindTo('duluth.timeline').set(t ? await upload(t) : t)}
            />

            <henshu.richtext className="belowgraphic" {...bindTo('Process.belowgraphic')} />

            {/*
            <List mode="radio" value={phase} onChange={setPhase}>
                <henshu.each {...bindTo('Process.items')}>
                    {(bindToEach, i) => (
                        <Accordion key={i} color={'#73152D'} value={i}>
                            <Title>
                                <henshu.span {...bindToEach('name')} /> -&nbsp;
                                <henshu.span {...bindToEach('title')} />
                            </Title>
                            <Header><henshu.span {...bindToEach('duration')} /></Header>
                            <Body>
                                <henshu.richtext {...bindToEach('body')} />
                            </Body>
                        </Accordion>
                    )}
                </henshu.each>
            </List>
            */}
        </Section>

        
    </>;
});
