const firebaseConfig = {
    apiKey: "AIzaSyAwL2ereHnVkvK0KWWLN8rXo7o9h0Zm6Z8",
    authDomain: "umn-engagement.firebaseapp.com",
    projectId: "umn-engagement",
    storageBucket: "umn-engagement.appspot.com",
    messagingSenderId: "751336061587",
    appId: "1:751336061587:web:c705cb0b35206011a42b66"
};

export default firebaseConfig;
