import { useState } from 'react';
import { observer } from 'mobx-react';
import { useHenshuApp } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';
import { Modal, Title, Body, Button } from '@strategies/ui';

import Section from '../Section';

import social from '../../assets/UMN-Framework for Planning-04.jpg';
import environmental from '../../assets/UMN-Framework for Planning-05.jpg';
import resources from '../../assets/UMN-Framework for Planning-06.jpg';
import economic from '../../assets/UMN-Framework for Planning-07.jpg';


const SYSTEMS = [
    {
        name: 'Social',
        img: social,
        width: '600px',
        colors: {
            primary: '#6E121C',
            secondary: '#E1CDD2',
        },
    },
    {
        name: 'Environmental',
        img: environmental,
        width: '960px',
        colors: {
            primary: '#788631',
            secondary: '#E4E7D4',
        },
    },
    {
        name: 'Resources',
        img: resources,
        width: '900px',
        colors: {
            primary: '#F6CD54',
            secondary: '#FDF5DA',
        },
    },
    {
        name: 'Economic',
        img: economic,
        width: '450px',
        colors: {
            primary: '#163A59',
            secondary: '#CFD8DD',
        },
    },

];

export default observer(function Vision() {
    const { upload } = useHenshuApp().persist;
    const { bindTo } = useHenshu();
    const [modal, setModal] = useState(false);
    const [system, setSystem] = useState(null);

    return <>
        <Section index={1} name="Vision">
            <henshu.richtext {...bindTo('Vision.body')} />

            <div className="row">
                <div className="col">
                    <div className="buttons">
                        {SYSTEMS.map(({ name, colors }, i) => (
                            <Button
                         
                                onClick={() => setSystem(i)}
                                style={{
                                    color: colors.primary,
                                    backgroundColor: colors.secondary,
                                    borderColor: colors.primary,
                                }}
                            >
                                {name}
                            </Button>
                        ))}
                    </div>

                    <henshu.h4 {...bindTo('Vision.historical.title')} />
                </div>

                <div className="col">
                    <henshu.img
                        get={() => bindTo('Vision.present.image').get()}
                        set={async (t) => bindTo('Vision.present.image').set(t ? await upload(t) : t)}
                    />

                    <henshu.h4 {...bindTo('Vision.present.title')} />
                </div>
            </div>
        </Section>

        <Modal active={modal} onClose={() => setModal(false)} mount={document.querySelector('.App')}>
            <Title><henshu.h2 {...bindTo('Vision.history.image.title')} /></Title>
            <Body>
                <henshu.img
                    get={() => bindTo('Vision.historical.image.full').get()}
                    set={async (t) => bindTo('Vision.historical.image.full').set(t ? await upload(t) : t)}
                />
            </Body>
        </Modal>

        {system !== null && (
            <Modal className="system-modal" active={true} onClose={() => setSystem(null)} mount={document.querySelector('.App')}>
                <Title>{SYSTEMS[system].name}</Title>
                <Body>
                    <img
                        src={SYSTEMS[system].img}
                        alt={SYSTEMS[system].name}
                        style={{
                            maxWidth: SYSTEMS[system].width,
                        }}
                    />
                </Body>
            </Modal>
        )}
    </>;
});
