import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHenshuApp } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';
import { FiImage, FiLink } from 'react-icons/fi';
import { Modal, Title, Body } from '@strategies/ui';
import DragDrop from '@strategies/react-drag-drop';

import Section from '../Section';
import rightArrowIcon from '../../assets/arrow-brand.svg';


const COLORS = [
    '#CF908A',
    '#FADDAA',
    '#92ACA3',
];

const ICONS = {
    link: <FiLink />,
    modal: <FiImage />,
};


const MODALS = {
    duluth: (bindTo, upload) => <>
        <henshu.h2 {...bindTo('modal.final.title')} />
        <henshu.richtext {...bindTo('modal.final.body')} />
        <henshu.h2 {...bindTo('modal.about.title')} />
        <henshu.richtext {...bindTo('modal.about.body')} />

        <henshu.img
            get={() => bindTo('image').get()}
            set={async buf => bindTo('image').set(buf ? await upload(buf) : '')}
        />

        <henshu.h2 {...bindTo('modal.events.title')} />
        <henshu.richtext {...bindTo('modal.events.body')} />
    </>
}

const getModal = (bindTo, upload) => {
    const _title = (bindTo('title').get() || '').toLowerCase();

    if (_title in MODALS) {
        return MODALS[_title](bindTo, upload);
    }

    return <henshu.richtext {...bindTo('modal.body')} />
};


const Resource = observer(({ bindTo, i }) => {
    const [modal, setModal] = useState(false);
    const [inputValue, setInputValue] = useState(bindTo('action.value').get());
    const { persist, isEditing } = useHenshuApp();
    const { upload } = persist;

    const action = bindTo('action').get() || 'modal';
    const isModal = action === 'modal';

    useEffect(() => {
        setInputValue('');
    }, [action]);

    useEffect(() => {
        //bindTo('action.value').set(inputValue);
    }, [inputValue]);

    const inner = <>
        {isEditing && (
            <henshu.img
                get={() => bindTo('image').get()}
                set={async buf => bindTo('image').set(buf ? await upload(buf) : '')}
            />
        )}

        <div className="resource-content">
            <div className="icon-wrapper">
                {ICONS[action]}
                <img src={rightArrowIcon} alt="" aria-hidden="true" />
            </div>

            <henshu.h4 {...bindTo('title')} />
            <henshu.p {...bindTo('subtitle')} />
        </div>

        {isModal && (
            <Modal mount={document.querySelector('.App')} active={modal} onClose={() => setModal(false)}>
                <Title><henshu.h3 {...bindTo('title')} /></Title>
                <Body>
                    {getModal(bindTo, upload)}
                </Body>
            </Modal>
        )}

        {isEditing && (
            <div className="resource-meta">
                <select
                    value={action}
                    onChange={async e => {
                        bindTo('action').set(e.target.value);
                    }}
                >
                    <option value={'modal'}>Modal</option>
                    <option value={'link'}>Link</option>
                </select>

                {action === 'link' && (
                    <input
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                    />
                )}

                {action === 'file' && (
                    <DragDrop onLoad={setInputValue} />
                )}
            </div>
        )}
    </>;

    const image = bindTo('image').get();

    return (
        <div
            onClick={!modal && !isEditing && isModal ? () => setModal(true) : () => {}}
            style={image ? ({backgroundImage: `url(${image})`}) : ({ background: COLORS[i % COLORS.length] })}
        >
            {!isModal && !isEditing ? <>
                <a
                    href={bindTo('action.value').get()}
                    rel="noopener noreferrer" target="_blank"
                >
                    {inner}
               </a>
            </> : inner}
        </div>
    );
});


export default observer(function Resources() {
    const { bindTo } = useHenshu();

    return (
        <Section index={4} name="Resources">
            <henshu.p {...bindTo('resources.body')} />

            <ul>
                <henshu.each {...bindTo('resources')}>
                    {(bindToEach, i) => {
                        const action = bindToEach('action').get() || 'modal';
                        const isModal = action === 'modal';

                        return (
                            <li key={i} className={isModal ? 'modal-item' : ''}>
                                <Resource i={i} bindTo={bindToEach} />
                            </li>
                        );
                    }}
                </henshu.each>
            </ul>
        </Section>
    );
});
