/*
 * Configuration
 */

export const ENGAGEMENT_LINK = 'https://comap.sasaki.com/umc/';

export const HEAP_ID = '1694559342';

export const FORMSPREE_URL = 'https://formspree.io/f/mzbwklbn';

export const MOBILE_BREAKPOINT = 1200;

export const MENU = [
    { text: 'Welcome', href: '#welcome' },
    { text: 'Vision', href: '#vision' },
    { text: 'Process', href: '#process' },
    { text: 'Share Your Ideas', href: '#share' },
    { text: 'Resources', href: '#resources' },
];
