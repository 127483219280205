import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { FORMSPREE_URL } from '../../config';


const OPTIONS = [
    'Student',
    'Faculty',
    'Staff',
    'Community Member',
    'Regional Partner',
];


const CAMPUSOPTIONS = [
    'Twin Cities',
    'Duluth',
    'Rochester',
    'Crookston',
    'Morris',
];
export default observer(function ContactForm() {
    const { bindTo } = useHenshu();
    const [status, setStatus] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const submitForm = useCallback(e => {
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                setStatus('SUCCESS');
            } else {
                setStatus("ERROR");
            }

            setSubmitting(false);
        };
        xhr.send(data);
    }, []);

    return (
        <div className="ContactForm">
            <henshu.richtext {...bindTo('Contact.body')} />

            <form
                className={submitting ? 'disabled' : ''}
                onSubmit={submitForm}
                action={FORMSPREE_URL}
                method="POST"
            >
                <div className="TextInput">
                    <henshu.label htmlFor="name" {...bindTo('Contact.prompt.name')} />
                    <input id="name" type="text" name="name" placeholder="Type Name Here ..." />
                </div>

                <div className="TextInput">
                    <henshu.label htmlFor="role" {...bindTo('Contact.prompt.role')} />

                    <div className="SelectInput">
                        <select id="role" name="role" defaultValue={'unspecified'}>
                            <option value="unspecified" disabled>Please select an option</option>
                            {OPTIONS.map(who => <option key={who} value={who}>{who}</option>)}
                        </select>
                    </div>
                </div>


                <div className="TextInput">
                    <henshu.label htmlFor="campus" {...bindTo('Contact.prompt.campus')} />

                    <div className="SelectInput">
                        <select id="campus" name="campus" defaultValue={'unspecified'}>
                            <option value="unspecified" disabled>Please select an option</option>
                            {CAMPUSOPTIONS.map(who => <option key={who} value={who}>{who}</option>)}
                        </select>
                    </div>
                </div>

                <div className="TextInput">
                    <henshu.label htmlFor="email" {...bindTo('Contact.prompt.email')} />
                    <input id="email" type="email" name="email" placeholder="Type Email Here ..." />
                </div>

                <div className="TextAreaInput">
                    <henshu.label htmlFor="email" {...bindTo('Contact.prompt.message')} />
                    <textarea id="message" name="message" placeholder="What do you want to share?" />
                </div>

                {status === "SUCCESS" ? (
                    <henshu.p {...bindTo('Contact.thanks')} />
                ) : (
                    <henshu.button
                        className="with-arrow blue"
                        onClick={() => setSubmitting(true)}
                        {...bindTo('Contact.submit')}
                    />
                )}

                {status === "ERROR" && <henshu.p {...bindTo('Contact.error')} />}
            </form>

            <henshu.p className="privacy" {...bindTo('privacy')} />
        </div>
    );
});
